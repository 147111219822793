<div class="errorMessage">
  <h1>
    Error 404 - Seite nicht gefunden.
  </h1>
  <h2>
    Die von Ihnen gewünschte Vorschau existiert nicht oder ist bereits veraltet. <br>
    Bitte kontaktieren Sie Ihren Mediaberater.
    <br><br>
    Mögliche Lösungen:
    <ul>
      <li>Laden Sie die Seite neu</li>
      <li>Löschen Sie Ihren Browser-Cache</li>
      <li>Überprüfen Sie den aufgerufenen Link</li>
    </ul>
  </h2>
</div>
