// noinspection SpellCheckingInspection
export const texts = Object.freeze({

  info: {
    start: 'Falls Änderungen gewünscht sind, kontaktieren Sie bitte Ihren Mediaberater.',
    expired: 'Diese Ansicht ist bereits veraltet. Bitte nutzen Sie den Ihnen zuletzt zugeschickten Vorschau-Link.',
    published: 'Diese Ansicht wurde soeben veröffentlicht.',
  },

  dialog: {
    header: 'Achtung!',
    expired: 'Ihre Firmenprofil-Vorschau ist nicht aktuell. Sie können die Vorschau ansehen, jedoch nicht veröffentlichen.\n'
      + 'Für eine aktuelle Ansicht nutzen Sie den Ihnen zuletzt zugeschickten Vorschau-Link.',
  },

  toasts: {
    published: 'Diese Ansicht wurde soeben veröffentlicht. Vielen Dank.',
  },

  buttons: {
    publish: 'Firmenprofil veröffentlichen',
    contact: 'Mediaberater kontaktieren'
  },

  headlines: {
    header: 'Ihr Firmenprofil',
    subheader: 'Bildschirm-Darstellungen'
  }

});
