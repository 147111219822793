<div class="container" *ngIf="loaded">
  <app-header title="{{TEXTS.headlines.header}}"></app-header>

  <div class="company-profile-wrapper">
    <div class="company-profile">

      <mat-card class="info">
        <img alt="info" src="../../assets/images/round-info-24px.svg"/>
        {{info}}
      </mat-card>

      <app-controls [token]="token$ | async"
                    [email]="email$ | async"
                   (infoChanged)="info = $event"></app-controls>

      <mat-divider></mat-divider>

      <app-resize (frameWidthChanged)="frameWidth = $event"></app-resize>

      <app-preview class="stretch"
                   [token]="token$ | async"
                   [frameWidth]="frameWidth"></app-preview>

    </div>
  </div>
</div>
