import * as Raven from 'raven-js';
import { ErrorHandler } from '@angular/core';
import { environment } from '../../environments/environment';

if (!!environment.sentryDSN) {
  Raven
    .config(environment.sentryDSN)
    .install();
}

export class RavenErrorHandler implements ErrorHandler {
  /*tslint:disable-next-line:no-any*/
  handleError(err: any): void {
    Raven.captureException(err.originalError || err);
  }
}

export function provideErrorHandler(): RavenErrorHandler | ErrorHandler {
  if (!environment.production) {
    return new ErrorHandler();
  }
  if (!environment.sentryDSN) {
    console.error('No Sentry DSN configured in environment.');
    return new ErrorHandler();
  }
  return new RavenErrorHandler();
}
