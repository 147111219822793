import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {BackendUrlHelper} from '../backend.url.helper';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {

  @Input() public frameWidth: number;
  @Input() public token: string;

  public previewUrl: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    const previewUrl: string = BackendUrlHelper.getPreviewUrl(this.token);
    this.previewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(previewUrl);
  }
}
