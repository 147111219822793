<div class="controls">

  <button mat-button value="contact" class="contact" (click)="send()">
    <img alt="mail" class="material-icons" src="../../../assets/images/outline-mail_outline-24px.svg"/>
    {{TEXTS.buttons.contact}}
  </button>

  <button mat-button value="publish" class="publish"
                     [disabled]="disablePublish" (click)="disablePublish || publish()">
    <img alt="check" class="material-icons" src="../../../assets/images/outline-check-24px.svg"/>
    {{TEXTS.buttons.publish}}
  </button>

</div>
