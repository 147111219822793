import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {catchError, map, switchMap, takeUntil, tap} from 'rxjs/operators';
import {Observable, of, Subject} from 'rxjs';
import {screenSizes} from './resize/resize.component';
import {texts} from './texts';
import {ProfileBackendService} from './backend.service';
import * as Raven from 'raven-js';

@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.scss']
})

export class CompanyProfileComponent implements OnInit, OnDestroy {

  public token$: Observable<string>;
  public email$: Observable<string>;
  public loaded = false;
  public TEXTS = texts;

  public info = texts.info.start;
  public frameWidth = screenSizes.desktop;

  private componentDestroyed$: Subject<{}> = new Subject<{}>();

  constructor(private route: ActivatedRoute,
              private backendService: ProfileBackendService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.token$ = this.route.queryParams.pipe(map(givenLink => givenLink.token));
    this.email$ = this.route.queryParams.pipe(map(givenMail => atob(givenMail.reply)));
    this.checkPreviewExists();
  }

  private checkPreviewExists() {
    this.token$.pipe(
      takeUntil(this.componentDestroyed$),
      switchMap(token => token ? this.backendService.previewExists(token) : this.previewExpired()),
      tap(() => this.loaded = true),
      catchError(() => this.previewExpired())
    ).subscribe();
  }

  private previewExpired() {
    // noinspection JSIgnoredPromiseFromCall
    this.router.navigate(['/404']);
    Raven.captureException('Preview does not exist', {level: 'error'});
    return of(null);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }
}
