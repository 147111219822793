import {Component, EventEmitter, Output} from '@angular/core';
import {texts} from '../texts';

export const screenSizes = {
  desktop: 1100,
  tablet: 768,
  mobile: 375
};

@Component({
  selector: 'app-resize',
  templateUrl: './resize.component.html',
  styleUrls: ['./resize.component.scss']
})
export class ResizeComponent {

  public SCREEN_SIZES = screenSizes;
  public TEXTS = texts;

  @Output()
  public frameWidthChanged: EventEmitter<number> = new EventEmitter<number>();

  public changeFrameWidth(width: number): void {
    this.frameWidthChanged.emit(width);
  }
}
