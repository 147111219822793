import { DialogComponent } from './dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
var DialogHelper = /** @class */ (function () {
    function DialogHelper(dialog) {
        this.dialog = dialog;
    }
    DialogHelper.prototype.open = function (data) {
        var config = new MatDialogConfig();
        config.data = data;
        return this.dialog.open(DialogComponent, config);
    };
    DialogHelper.ngInjectableDef = i0.defineInjectable({ factory: function DialogHelper_Factory() { return new DialogHelper(i0.inject(i1.MatDialog)); }, token: DialogHelper, providedIn: "root" });
    return DialogHelper;
}());
export { DialogHelper };
