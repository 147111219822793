import {environment} from '../../environments/environment';

export class BackendUrlHelper {

  public static getPreviewUrl(token: string): string {
    return environment.previewUrl + '/' + token + '/';
  }

  public static getPublishUrl(token: string): string {
      return environment.publishUrl + '/' + token;
  }
}
