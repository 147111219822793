import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {APP_CONFIG, AppConfig} from './app.config';
import {
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatDialog,
  MatDialogModule, MatDividerModule,
  MatSnackBarModule,
  MatToolbarModule
} from '@angular/material';
import {NotFoundComponent} from './not-found/not-found.component';
import {HttpClientModule} from '@angular/common/http';
import {HeaderComponent} from './header/header.component';
import {DialogComponent} from './dialog/dialog.component';
import {FormsModule} from '@angular/forms';
import {CompanyProfileComponent} from './company-profile/company-profile.component';
import {PreviewComponent} from './company-profile/preview/preview.component';
import {ResizeComponent} from './company-profile/resize/resize.component';
import {ControlsComponent} from './company-profile/controls/controls.component';
import {provideErrorHandler} from './shared/raven-error-handler';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    HeaderComponent,
    DialogComponent,
    CompanyProfileComponent,
    PreviewComponent,
    ResizeComponent,
    ControlsComponent
  ],
  entryComponents: [
    DialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,

    MatButtonModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatCardModule,
    MatDividerModule
  ],
  providers: [
    MatDialog,
    {provide: APP_CONFIG, useValue: AppConfig},
    {provide: ErrorHandler, useFactory: provideErrorHandler}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
