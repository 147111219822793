/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./company-profile.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../header/header.component.ngfactory";
import * as i3 from "../header/header.component";
import * as i4 from "../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i5 from "@angular/material/card";
import * as i6 from "./controls/controls.component.ngfactory";
import * as i7 from "./controls/controls.component";
import * as i8 from "./backend.service";
import * as i9 from "../dialog/dialog.helper";
import * as i10 from "@angular/material/snack-bar";
import * as i11 from "@angular/common";
import * as i12 from "../../../node_modules/@angular/material/divider/typings/index.ngfactory";
import * as i13 from "@angular/material/divider";
import * as i14 from "./resize/resize.component.ngfactory";
import * as i15 from "./resize/resize.component";
import * as i16 from "./preview/preview.component.ngfactory";
import * as i17 from "./preview/preview.component";
import * as i18 from "@angular/platform-browser";
import * as i19 from "./company-profile.component";
import * as i20 from "@angular/router";
var styles_CompanyProfileComponent = [i0.styles];
var RenderType_CompanyProfileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CompanyProfileComponent, data: {} });
export { RenderType_CompanyProfileComponent as RenderType_CompanyProfileComponent };
function View_CompanyProfileComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(2, 49152, null, 0, i3.HeaderComponent, [], { title: [0, "title"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 16, "div", [["class", "company-profile-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 15, "div", [["class", "company-profile"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "mat-card", [["class", "info mat-card"]], null, null, null, i4.View_MatCard_0, i4.RenderType_MatCard)), i1.ɵdid(6, 49152, null, 0, i5.MatCard, [], null, null), (_l()(), i1.ɵeld(7, 0, null, 0, 0, "img", [["alt", "info"], ["src", "../../assets/images/round-info-24px.svg"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, 0, [" ", " "])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "app-controls", [], null, [[null, "infoChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("infoChanged" === en)) {
        var pd_0 = ((_co.info = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ControlsComponent_0, i6.RenderType_ControlsComponent)), i1.ɵdid(10, 245760, null, 0, i7.ControlsComponent, [i8.ProfileBackendService, i9.DialogHelper, i10.MatSnackBar], { token: [0, "token"], email: [1, "email"] }, { infoChanged: "infoChanged" }), i1.ɵpid(131072, i11.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i11.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(13, 0, null, null, 1, "mat-divider", [["class", "mat-divider"], ["role", "separator"]], [[1, "aria-orientation", 0], [2, "mat-divider-vertical", null], [2, "mat-divider-horizontal", null], [2, "mat-divider-inset", null]], null, null, i12.View_MatDivider_0, i12.RenderType_MatDivider)), i1.ɵdid(14, 49152, null, 0, i13.MatDivider, [], null, null), (_l()(), i1.ɵeld(15, 0, null, null, 1, "app-resize", [], null, [[null, "frameWidthChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("frameWidthChanged" === en)) {
        var pd_0 = ((_co.frameWidth = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_ResizeComponent_0, i14.RenderType_ResizeComponent)), i1.ɵdid(16, 49152, null, 0, i15.ResizeComponent, [], null, { frameWidthChanged: "frameWidthChanged" }), (_l()(), i1.ɵeld(17, 0, null, null, 2, "app-preview", [["class", "stretch"]], null, null, null, i16.View_PreviewComponent_0, i16.RenderType_PreviewComponent)), i1.ɵdid(18, 114688, null, 0, i17.PreviewComponent, [i18.DomSanitizer], { frameWidth: [0, "frameWidth"], token: [1, "token"] }, null), i1.ɵpid(131072, i11.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.TEXTS.headlines.header, ""); _ck(_v, 2, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform(_co.token$)); var currVal_3 = i1.ɵunv(_v, 10, 1, i1.ɵnov(_v, 12).transform(_co.email$)); _ck(_v, 10, 0, currVal_2, currVal_3); var currVal_8 = _co.frameWidth; var currVal_9 = i1.ɵunv(_v, 18, 1, i1.ɵnov(_v, 19).transform(_co.token$)); _ck(_v, 18, 0, currVal_8, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.info; _ck(_v, 8, 0, currVal_1); var currVal_4 = (i1.ɵnov(_v, 14).vertical ? "vertical" : "horizontal"); var currVal_5 = i1.ɵnov(_v, 14).vertical; var currVal_6 = !i1.ɵnov(_v, 14).vertical; var currVal_7 = i1.ɵnov(_v, 14).inset; _ck(_v, 13, 0, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_CompanyProfileComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CompanyProfileComponent_1)), i1.ɵdid(1, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loaded; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CompanyProfileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-company-profile", [], null, null, null, View_CompanyProfileComponent_0, RenderType_CompanyProfileComponent)), i1.ɵdid(1, 245760, null, 0, i19.CompanyProfileComponent, [i20.ActivatedRoute, i8.ProfileBackendService, i20.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CompanyProfileComponentNgFactory = i1.ɵccf("app-company-profile", i19.CompanyProfileComponent, View_CompanyProfileComponent_Host_0, {}, {}, []);
export { CompanyProfileComponentNgFactory as CompanyProfileComponentNgFactory };
