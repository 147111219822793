/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./preview.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./preview.component";
import * as i3 from "@angular/platform-browser";
var styles_PreviewComponent = [i0.styles];
var RenderType_PreviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PreviewComponent, data: {} });
export { RenderType_PreviewComponent as RenderType_PreviewComponent };
export function View_PreviewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "frame-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "iframe", [["allow", "autoplay;fullscreen"], ["class", "frame-preview"]], [[8, "src", 5], [8, "width", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.previewUrl; var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.frameWidth, "px"); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_PreviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-preview", [], null, null, null, View_PreviewComponent_0, RenderType_PreviewComponent)), i1.ɵdid(1, 114688, null, 0, i2.PreviewComponent, [i3.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PreviewComponentNgFactory = i1.ɵccf("app-preview", i2.PreviewComponent, View_PreviewComponent_Host_0, { frameWidth: "frameWidth", token: "token" }, {}, []);
export { PreviewComponentNgFactory as PreviewComponentNgFactory };
