/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./resize.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/material/button-toggle";
import * as i4 from "../../../../node_modules/@angular/material/button-toggle/typings/index.ngfactory";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "./resize.component";
var styles_ResizeComponent = [i0.styles];
var RenderType_ResizeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ResizeComponent, data: {} });
export { RenderType_ResizeComponent as RenderType_ResizeComponent };
export function View_ResizeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 14, "div", [["class", "resize-options"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 13, "mat-button-toggle-group", [["appearance", "legacy"], ["class", "mat-button-toggle-group"], ["role", "group"], ["value", "desktop"]], [[1, "aria-disabled", 0], [2, "mat-button-toggle-vertical", null], [2, "mat-button-toggle-group-appearance-standard", null]], null, null, null, null)), i1.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.MatButtonToggleGroup]), i1.ɵprd(6144, null, i3.MatButtonToggleGroupMultiple, null, [i3.MatButtonToggleGroup]), i1.ɵdid(6, 1130496, null, 1, i3.MatButtonToggleGroup, [i1.ChangeDetectorRef, [2, i3.MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS]], { appearance: [0, "appearance"], value: [1, "value"] }, null), i1.ɵqud(603979776, 1, { _buttonToggles: 1 }), (_l()(), i1.ɵeld(8, 0, null, null, 2, "mat-button-toggle", [["class", "mat-button-toggle"], ["value", "desktop"]], [[2, "mat-button-toggle-standalone", null], [2, "mat-button-toggle-checked", null], [2, "mat-button-toggle-disabled", null], [2, "mat-button-toggle-appearance-standard", null], [1, "tabindex", 0], [1, "id", 0]], [[null, "click"], [null, "focus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).focus() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.changeFrameWidth(_co.SCREEN_SIZES.desktop) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_MatButtonToggle_0, i4.RenderType_MatButtonToggle)), i1.ɵdid(9, 245760, [[1, 4]], 0, i3.MatButtonToggle, [[2, i3.MatButtonToggleGroup], i1.ChangeDetectorRef, i1.ElementRef, i5.FocusMonitor, [8, null], [2, i3.MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(-1, 0, ["Desktop"])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "mat-button-toggle", [["class", "mat-button-toggle"], ["value", "tablet"]], [[2, "mat-button-toggle-standalone", null], [2, "mat-button-toggle-checked", null], [2, "mat-button-toggle-disabled", null], [2, "mat-button-toggle-appearance-standard", null], [1, "tabindex", 0], [1, "id", 0]], [[null, "click"], [null, "focus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).focus() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.changeFrameWidth(_co.SCREEN_SIZES.tablet) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_MatButtonToggle_0, i4.RenderType_MatButtonToggle)), i1.ɵdid(12, 245760, [[1, 4]], 0, i3.MatButtonToggle, [[2, i3.MatButtonToggleGroup], i1.ChangeDetectorRef, i1.ElementRef, i5.FocusMonitor, [8, null], [2, i3.MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(-1, 0, ["Tablet"])), (_l()(), i1.ɵeld(14, 0, null, null, 2, "mat-button-toggle", [["class", "mat-button-toggle"], ["value", "mobil"]], [[2, "mat-button-toggle-standalone", null], [2, "mat-button-toggle-checked", null], [2, "mat-button-toggle-disabled", null], [2, "mat-button-toggle-appearance-standard", null], [1, "tabindex", 0], [1, "id", 0]], [[null, "click"], [null, "focus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).focus() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.changeFrameWidth(_co.SCREEN_SIZES.mobile) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_MatButtonToggle_0, i4.RenderType_MatButtonToggle)), i1.ɵdid(15, 245760, [[1, 4]], 0, i3.MatButtonToggle, [[2, i3.MatButtonToggleGroup], i1.ChangeDetectorRef, i1.ElementRef, i5.FocusMonitor, [8, null], [2, i3.MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(-1, 0, ["Mobil"]))], function (_ck, _v) { var currVal_4 = "legacy"; var currVal_5 = "desktop"; _ck(_v, 6, 0, currVal_4, currVal_5); var currVal_12 = "desktop"; _ck(_v, 9, 0, currVal_12); var currVal_19 = "tablet"; _ck(_v, 12, 0, currVal_19); var currVal_26 = "mobil"; _ck(_v, 15, 0, currVal_26); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.TEXTS.headlines.subheader; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 6).disabled; var currVal_2 = i1.ɵnov(_v, 6).vertical; var currVal_3 = (i1.ɵnov(_v, 6).appearance === "standard"); _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); var currVal_6 = !i1.ɵnov(_v, 9).buttonToggleGroup; var currVal_7 = i1.ɵnov(_v, 9).checked; var currVal_8 = i1.ɵnov(_v, 9).disabled; var currVal_9 = (i1.ɵnov(_v, 9).appearance === "standard"); var currVal_10 = (0 - 1); var currVal_11 = i1.ɵnov(_v, 9).id; _ck(_v, 8, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11); var currVal_13 = !i1.ɵnov(_v, 12).buttonToggleGroup; var currVal_14 = i1.ɵnov(_v, 12).checked; var currVal_15 = i1.ɵnov(_v, 12).disabled; var currVal_16 = (i1.ɵnov(_v, 12).appearance === "standard"); var currVal_17 = (0 - 1); var currVal_18 = i1.ɵnov(_v, 12).id; _ck(_v, 11, 0, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18); var currVal_20 = !i1.ɵnov(_v, 15).buttonToggleGroup; var currVal_21 = i1.ɵnov(_v, 15).checked; var currVal_22 = i1.ɵnov(_v, 15).disabled; var currVal_23 = (i1.ɵnov(_v, 15).appearance === "standard"); var currVal_24 = (0 - 1); var currVal_25 = i1.ɵnov(_v, 15).id; _ck(_v, 14, 0, currVal_20, currVal_21, currVal_22, currVal_23, currVal_24, currVal_25); }); }
export function View_ResizeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-resize", [], null, null, null, View_ResizeComponent_0, RenderType_ResizeComponent)), i1.ɵdid(1, 49152, null, 0, i6.ResizeComponent, [], null, null)], null, null); }
var ResizeComponentNgFactory = i1.ɵccf("app-resize", i6.ResizeComponent, View_ResizeComponent_Host_0, {}, { frameWidthChanged: "frameWidthChanged" }, []);
export { ResizeComponentNgFactory as ResizeComponentNgFactory };
