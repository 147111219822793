import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {NotFoundComponent} from './not-found/not-found.component';
import {CompanyProfileComponent} from './company-profile/company-profile.component';

export const routes: Routes = [
  {path: 'company-profile', component: CompanyProfileComponent},
  {path: '404', component: NotFoundComponent},
  {path: '', redirectTo: '404', pathMatch: 'full'},
  {path: '**', redirectTo: '404'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
