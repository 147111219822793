import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ProfileBackendService} from '../backend.service';
import {texts} from '../texts';
import {DialogHelper} from '../../dialog/dialog.helper';
import {catchError, takeUntil, tap} from 'rxjs/operators';
import {Observable, of, Subject} from 'rxjs';
import {MatSnackBar} from '@angular/material';
import * as Raven from 'raven-js';

@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss']
})
export class ControlsComponent implements OnInit, OnDestroy {

  @Output() public infoChanged = new EventEmitter<string>();

  @Input() public token: string;
  @Input() public email: string;

  public disablePublish = true;
  public TEXTS = texts;
  private componentDestroyed$: Subject<{}> = new Subject<{}>();

  constructor(
    private backend: ProfileBackendService,
    private dialog: DialogHelper,
    private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.backend.canPublish(this.token).pipe(
      takeUntil(this.componentDestroyed$),
      tap(() => this.disablePublish = false),
      catchError(() => this.outdated())
  ).subscribe();
  }

  public send(): void {
    location.href = 'mailto:' + this.email;
  }

  public publish() {
    this.backend.publish(this.token).pipe(
      takeUntil(this.componentDestroyed$),
      tap(() => this.published()),
      catchError(err => this.error(err))
    ).subscribe();
  }

  private published(): void {
    this.disablePublish = true;
    this.infoChanged.next(texts.info.published);
    this.snackBar.open(texts.toasts.published, 'OK', {
      duration: 3000,
      panelClass: ['confirm-snackbar']
    });
  }

  private outdated(): Observable<any> {
    this.infoChanged.next(texts.info.expired);
    this.dialog.open({message: texts.dialog.expired});
    Raven.captureException('Outdated Preview', {level: 'info'});
    return of(null);
  }

  private error(error: any): Observable<any> {
    Raven.captureException('Publish Error', {level: 'critical'});
    return of(null);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }
}
