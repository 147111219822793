/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./controls.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./controls.component";
import * as i8 from "../backend.service";
import * as i9 from "../../dialog/dialog.helper";
import * as i10 from "@angular/material/snack-bar";
var styles_ControlsComponent = [i0.styles];
var RenderType_ControlsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ControlsComponent, data: {} });
export { RenderType_ControlsComponent as RenderType_ControlsComponent };
export function View_ControlsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "controls"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "button", [["class", "contact"], ["mat-button", ""], ["value", "contact"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.send() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(2, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 0, "img", [["alt", "mail"], ["class", "material-icons"], ["src", "../../../assets/images/outline-mail_outline-24px.svg"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, 0, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "button", [["class", "publish"], ["mat-button", ""], ["value", "publish"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.disablePublish || _co.publish()) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(6, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵeld(7, 0, null, 0, 0, "img", [["alt", "check"], ["class", "material-icons"], ["src", "../../../assets/images/outline-check-24px.svg"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, 0, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.disablePublish; _ck(_v, 6, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.TEXTS.buttons.contact; _ck(_v, 4, 0, currVal_2); var currVal_3 = (i1.ɵnov(_v, 6).disabled || null); var currVal_4 = (i1.ɵnov(_v, 6)._animationMode === "NoopAnimations"); _ck(_v, 5, 0, currVal_3, currVal_4); var currVal_6 = _co.TEXTS.buttons.publish; _ck(_v, 8, 0, currVal_6); }); }
export function View_ControlsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-controls", [], null, null, null, View_ControlsComponent_0, RenderType_ControlsComponent)), i1.ɵdid(1, 245760, null, 0, i7.ControlsComponent, [i8.ProfileBackendService, i9.DialogHelper, i10.MatSnackBar], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ControlsComponentNgFactory = i1.ɵccf("app-controls", i7.ControlsComponent, View_ControlsComponent_Host_0, { token: "token", email: "email" }, { infoChanged: "infoChanged" }, []);
export { ControlsComponentNgFactory as ControlsComponentNgFactory };
