import { HttpClient } from '@angular/common/http';
import { BackendUrlHelper } from './backend.url.helper';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ProfileBackendService = /** @class */ (function () {
    function ProfileBackendService(http) {
        this.http = http;
    }
    ProfileBackendService.prototype.previewExists = function (token) {
        return this.http.head(BackendUrlHelper.getPreviewUrl(token));
    };
    ProfileBackendService.prototype.canPublish = function (token) {
        return this.http.head(BackendUrlHelper.getPublishUrl(token));
    };
    ProfileBackendService.prototype.publish = function (token) {
        return this.http.post(BackendUrlHelper.getPublishUrl(token), null);
    };
    ProfileBackendService.ngInjectableDef = i0.defineInjectable({ factory: function ProfileBackendService_Factory() { return new ProfileBackendService(i0.inject(i1.HttpClient)); }, token: ProfileBackendService, providedIn: "root" });
    return ProfileBackendService;
}());
export { ProfileBackendService };
