import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BackendUrlHelper} from './backend.url.helper';

export interface IProfileBackendService {
  previewExists(token: string): Observable<any>;
  canPublish(token: string): Observable<any>;
  publish(token: string): Observable<any>;
}

@Injectable({providedIn: 'root'})
export class ProfileBackendService implements IProfileBackendService {

  constructor(private http: HttpClient) {
  }

  public previewExists(token: string): Observable<any> {
    return this.http.head(BackendUrlHelper.getPreviewUrl(token));
  }

  public canPublish(token: string): Observable<any> {
    return this.http.head(BackendUrlHelper.getPublishUrl(token));
  }

  public publish(token: string): Observable<any> {
    return this.http.post(BackendUrlHelper.getPublishUrl(token), null);
  }
}
