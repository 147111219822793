import { AppConfig } from './app.config';
import { provideErrorHandler } from './shared/raven-error-handler';
var ɵ0 = AppConfig, ɵ1 = provideErrorHandler;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1 };
