import {Injectable} from '@angular/core';
import {DialogComponent, DialogData} from './dialog.component';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material';

@Injectable({providedIn: 'root'})
export class DialogHelper {

  constructor(private dialog: MatDialog) {
  }

  public open(data: DialogData): MatDialogRef<DialogComponent> {
    const config: MatDialogConfig = new MatDialogConfig();
    config.data = data;
    return this.dialog.open(DialogComponent, config);
  }
}
